import { NavigateOptions, useNavigate } from 'react-router-dom';

export const useCustomNavigate = () => {
    const navigate = useNavigate();

    const customNavigate = (url: string, options?: NavigateOptions) => {
        const samlId = localStorage.getItem('saml_id');
        navigate({
            pathname: url,
            ...(samlId && { search: `${samlId ? `?saml_id=${samlId}` : ''}` }),
            ...options,
        });
    };

    const addParamsToUrl = (url: string, params: string[]) => {
        const queryParams: string = params.reduce((acc, param) => {
            const localStorageParam = localStorage.getItem(param);
            if (localStorageParam) {
                acc += `${param}=${localStorageParam}&`;
            }
            return acc;
        }, '?');

        return `${url}${queryParams.slice(0, -1)}`;
    };

    return { navigate: customNavigate, addParamsToUrl };
};

import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@fluentui/react-components';
import { useSelector } from 'react-redux';

import { userSettingsReducerValues } from 'src/components/UserSettings/reducer';
import { SHOW_MODAL, Modals } from 'src/components';
import { useGeneralSelector, useActualStructures } from 'src/hooks';
import { shortStringDate, emptyArray, toString, uniqueArray } from 'src/tools';
import {
    FULL_SENSOR_OPERATIONS,
    PARTIAL_SENSOR_OPERATIONS,
    NO_SENSOR_OPERATIONS,
} from 'src/Chapters/Services/FpcMonitoring/constants';

import { SensorData } from '../PcSensorsInfo/interfaces';

import { SensorsHeatMap } from './SensorsHeatMap';
import { DetailedTable } from './DetailedTable';
import { ListContainer, ListItem, TextBox, ValueBox, ButtonContainer } from './styles';

export const RELATIVES_TYPES = ['zone', 'passway', 'tenant', 'location'];

const DATE_TIME_SELECT_NAME = 'period';

const findEntity = (entities: object[], fieldName: string, valueToSeek: string | number) =>
    (entities as object[]).find((item) => {
        return item?.[fieldName] === valueToSeek;
    });

export const Summary = ({ sensorsData }: { sensorsData: SensorData[] }) => {
    const { t } = useTranslation();

    const { lang } = useGeneralSelector();

    const [modalTitle, setModalTitle] = useState<string | null>(null);

    const [data, hasProblems] = useMemo(() => {
        const fullNumber = sensorsData.filter((item) => item.operation === 'full').length;
        const partialNumber = sensorsData.filter((item) => item.operation === 'partial').length;
        const notWorkingNumber = sensorsData.filter((item) => item.operation === 'no').length;

        return [
            [
                {
                    text: t('Fully operational sensors'),
                    value: fullNumber,
                    color: 'white',
                    bgColor: FULL_SENSOR_OPERATIONS.fill,
                },
                {
                    text: t('Partially operational sensors'),
                    value: partialNumber,
                    color: 'white',
                    bgColor: PARTIAL_SENSOR_OPERATIONS.fill,
                },
                {
                    text: t('Not operational sensors'),
                    value: notWorkingNumber,
                    color: 'white',
                    bgColor: NO_SENSOR_OPERATIONS.fill,
                },
            ],
            partialNumber > 0 || notWorkingNumber > 0,
        ];
    }, [sensorsData, t]);

    const { actualStructures } = useActualStructures();
    const sensorsDetailedData = useMemo(() => {
        const actualTenants = actualStructures?.['relationsTenant2location'] || emptyArray;
        const actualZones = actualStructures?.['relationsTenant2zone'] || emptyArray;

        const objects = actualStructures?.['elementsAdminDataObjects'] || emptyArray;
        const actualPassways = objects.filter((item: object) => item?.['objectType'] === 'passway');
        const actualPerimeters = objects.filter((item: object) => item?.['objectType'] === 'location');

        const sensorsRelatives = actualStructures?.['relationsSensor2dataobj'] || emptyArray;
        const actualRelatives = sensorsRelatives.filter((item: object) =>
            RELATIVES_TYPES.includes(item?.['dataobjType']),
        );

        // console.log(actualRelatives)
        // // console.log(sensorsRelatives)
        // console.log(uniqueArray(sensorsRelatives.map(({ dataobjType }) => (dataobjType))))
        // // console.log(actualRelatives.filter(({ dataobjType }) => (dataobjType === 'location')));
        // console.log(objects.filter((item: object) => (item?.['objectType'] === 'location')));

        const findRelative = (type: string, sensorId: number) =>
            (actualRelatives as object[]).find(
                (item) => item?.['dataobjType'] === type && item?.['sensorId'] === sensorId,
            );

        return sensorsData.map(({ id, operation, fill: _, ...sensor }) => {
            const passwayMarker = findRelative('passway', id)?.['dataobjMarker'];
            const passwayName = findEntity(actualPassways, 'marker', passwayMarker)?.['name'];

            const tenantMarker = findRelative('tenant', id)?.['dataobjMarker'];
            const tenantName = findEntity(actualTenants, 'tenantMarker', tenantMarker)?.['tenantName'];

            const zoneMarker = findRelative('zone', id)?.['dataobjMarker'];
            const zoneName = findEntity(actualZones, 'zoneMarker', zoneMarker)?.['zoneName'];

            const perimeterMarker = findRelative('location', id)?.['dataobjMarker'];
            const perimeterName = findEntity(actualPerimeters, 'marker', perimeterMarker)?.['objectName'];

            return {
                ...sensor,
                id: toString(id),
                status: operation,
                perimeterRelations: (perimeterMarker || '') + (!!perimeterName ? ` (${perimeterName || ''})` : ''),
                passwaysRelations: (passwayMarker || '') + (!!passwayName ? ` (${passwayName || ''})` : ''),
                tenantsRelations: (tenantMarker || '') + (!!tenantName ? ` (${tenantName || ''})` : ''),
                zonesRelations: (zoneMarker || '') + (!!zoneName ? ` (${zoneName || ''})` : ''),
            };
        });
    }, [sensorsData, actualStructures]);

    const modalBody =
        modalTitle === 'Detailed data' ? (
            <DetailedTable detailedData={sensorsDetailedData} />
        ) : modalTitle === '' ? (
            <SensorsHeatMap sensors={sensorsData} mode="full" />
        ) : null;

    const { currentOptions } = useSelector(userSettingsReducerValues);

    const dateText = currentOptions?.[DATE_TIME_SELECT_NAME]?.[0]
        ? shortStringDate(
              [currentOptions[DATE_TIME_SELECT_NAME][0].text, currentOptions[DATE_TIME_SELECT_NAME][1].text],
              lang,
          )
        : '';

    return (
        <div>
            <ListContainer>
                {data.map((item, index) => (
                    <ListItem key={`${item.bgColor}-${index}`}>
                        <TextBox>{item.text}</TextBox>
                        <ValueBox color={item.color} bgColor={item.bgColor}>
                            {item.value}
                        </ValueBox>
                    </ListItem>
                ))}
            </ListContainer>
            <ButtonContainer>
                <Button appearance="primary" onClick={() => setModalTitle('Detailed data')}>
                    {t('Detailed data')}
                </Button>
                <Button appearance="primary" disabled={!hasProblems} onClick={() => setModalTitle('')}>
                    {t('Show problems')}
                </Button>
            </ButtonContainer>
            <Modals
                title={`${t(modalTitle ?? '')} ${dateText}`}
                modalStatus={modalTitle !== null ? SHOW_MODAL : undefined}
                closeModal={() => setModalTitle(null)}
            >
                {modalBody}
            </Modals>
        </div>
    );
};

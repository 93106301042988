import { TFunction, TTranslationNS } from 'src/hooks/useTranslation';
import { emptyArray, uniqueArray } from 'src/tools';

import { PointsOfSalesResponse, PointOfSale, ReportingObjectsById } from '../interfaces';
// import { TenantPosInfo } from '../../../api/interfaces';

import { posDataByTenantId, aggregateField } from './helpers';

export function tenantsAndPosAdapter({
    response,
    allTimeResponse,
    reportingObjectsById,
    t,
}: {
    response: PointsOfSalesResponse;
    allTimeResponse: PointsOfSalesResponse;
    reportingObjectsById: ReportingObjectsById;
    t: TFunction<TTranslationNS>;
}) {
    const { results = emptyArray as PointOfSale[] } = response?.data;

    const possByTenant = posDataByTenantId(results, reportingObjectsById, t);
    const allTimePossByTenant = allTimeResponse
        ? posDataByTenantId(allTimeResponse?.data?.results || results, reportingObjectsById, t)
        : possByTenant;

    const tenantData = Object.keys(possByTenant).reduce((acc, tenantId) => {
        const tenantPoss = possByTenant[tenantId];
        const tenantAllPoss = allTimePossByTenant[tenantId] || emptyArray;

        const uniqueInns = [...new Set(aggregateField(tenantPoss, ({ inn }) => inn))];

        const tenantName = reportingObjectsById[tenantId].object_name;

        const [pos] = tenantPoss;

        const uniqRegIds = uniqueArray(
            (tenantPoss as { registrationId: number }[])
                .filter((item) => item?.registrationId >=0)
                .map((pos) => pos.registrationId),
        );

        const uniqAllRegIds = uniqueArray(
            (tenantAllPoss as Array<{ registrationId: number }>)
                .filter((item) => item?.registrationId >=0)
                .map((pos) => pos.registrationId),
        );

        // const connectorsCount = tenantPoss
        //     .reduce(
        //         (acc, pos) => ([
        //             ...acc,
        //             ...(pos.id.split('|')),
        //         ]),
        //         [],
        //     )
        //     .length;

        const tenantData = {
            id: tenantId,
            tenantName,
            orgName: pos.orgName,
            inns: uniqueInns.filter((inn) => inn?.length > 0).join(', '),
            posType: uniqueArray(aggregateField(tenantPoss, ({ posType }) => posType))
                .sort()
                .join(', '),
            // posName: aggregateField(tenantPoss, 'posName').join(', '),
            // posId: posData.id, // name todo retrieve from data may be dataseries for chart
            // posCount: count === activeCount ? count.toString() : `${activeCount}/${count}`,
            // posCount: uniqRegIds.length !== (connectorsCount || 0)
            //     ? `(${connectorsCount || 0}) ${uniqRegIds.length}`
            //     : `${uniqRegIds.length}`,
            posCount: uniqRegIds.length,
            posMaxCount: allTimePossByTenant[tenantId] !== undefined ? uniqAllRegIds.length : -1,
        };

        return [...acc, tenantData];
    }, []);

    return tenantData;
}

import DeleteConfirmModalBody from './components/DeleteConfirmModalBody/DeleteConfirmModalBody';
import Modals from '../../../../../../../components/Modals/Modals';
import ReportingObjectsSelect from './components/ReportingObjectsSelect/ReportingObjectsSelect';
import { ActionKind } from './interfaces';
import {
    ButtonsWrapper,
    EventTypeAndPeriodWrapper,
    HalfWidthWrapper,
    InputWrapper,
    Label,
    TimesWrapper,
    Wrapper,
} from './styles/styles';
import { Events_EventsMap_Widget_Reducer_Values, storeEventsToUpdate, storeNewEvents } from '../../reducer';
import { FC, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { generalReducerValues } from '../../../../../../../General.reducer';
import { IEvent, IExtendedEvent } from '../../interfaces';
import { initialState, reducer } from './reducer';
import { stringDate } from '../../../../../../../tools/Strings/stringDate';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import './styles/styles.scss';
import { TSelectedOptions } from '../../../../../../../components/Selects/Select/interfaces';
import Select from '../../../../../../../components/Selects/Select/Select';
import Button from '../../../../../../../components/Button/Button';
import TextInput from '../../../../../../../components/TextInput/TextInput';
import DateRangePicker from '../../../../../../../components/DateRangePicker/DateRangePicker';
import ObjectsSelect from '../../../../../../../components/Selects/ObjectsSelect/ObjectsSelect';
import { IModuleConfig } from '../../../../../../../General.interfaces';
import { IChangeOptionsArgs } from '../../../../../../../components/UserSettings/interfaces';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';

interface IProps {
    /** Выбранное событие */
    event?: IExtendedEvent | IEvent;
    /** Закрытие окна создания / редактирования события */
    closeModal: () => void;
}

/**
 * Компонент для отображения тела модального окна для создания нового ивента или новых
 */
const EventModalBody: FC<IProps> = ({ event, closeModal }) => {
    const [deleteConfirmModalStatus, setDeleteConfirmModalStatus] = useState({ show: false });
    const [periodModalStatus, setPeriodModalStatus] = useState({ show: false });
    const [state, dispatch] = useReducer(reducer, initialState);

    let { navigate } = useCustomNavigate();
    const [isDisabledButtonAdd, setIsDisabledButtonAdd] = useState<boolean>(false);
    const {
        lang,
        timeZone,
        selectedLocationId,
        cfg: { reportingObjectsById },
        currentModuleID,
        user,
        modulesConfig,
    } = useSelector(generalReducerValues);
    const { eventTypes } = useSelector(Events_EventsMap_Widget_Reducer_Values);
    const { t } = useTranslation();

    const reduxDispatch = useDispatch();

    /** Запись событий, которые нужно обновить */
    useEffect(() => {
        if (state.eventToUpdate) {
            reduxDispatch(storeEventsToUpdate([state.eventToUpdate]));
            dispatch({ type: ActionKind.StoreEventToUpdate, payload: null });
            closeModal();
        }
    }, [state.eventToUpdate]);

    /** Запись новых событий */
    useEffect(() => {
        if (state.eventsToCreate.length) {
            reduxDispatch(storeNewEvents(state.eventsToCreate));
            dispatch({ type: ActionKind.ResetReducer, payload: null });
            closeModal();
        }
    }, [state.eventsToCreate]);

    /** Запись данных в поля, если было выбрано событие */
    useEffect(() => {
        dispatch({ type: ActionKind.ResetReducer, payload: null });
        if (event) {
            const eventType = eventTypes?.find((item) => item.id === event.event_type);
            dispatch({
                type: ActionKind.FillDataFromEvent,
                payload: { event: { ...event, eventType }, reportingObjectsById },
            });
        }
    }, [event]);

    useEffect(() => {
        if (user) {
            const objResult = modulesConfig.find((item: IModuleConfig) => {
                return item.module_id === 'Configuration:Events';
            });
            setIsDisabledButtonAdd(() => !objResult?.permissions?.every((item) => user?.permissions?.includes(item)));
        }
    }, [user, modulesConfig]);

    const openPeriodModal = () => {
        setPeriodModalStatus({ show: true });
    };

    const closePeriodModal = () => {
        setPeriodModalStatus({ show: false });
    };

    const closeDeleteConfirmModal = () => {
        setDeleteConfirmModalStatus({ show: false });
    };

    const onDelete = () => {
        setDeleteConfirmModalStatus({ show: true });
    };

    const onNameChange = (args: { value: string; name: undefined; id: undefined }) => {
        dispatch({ type: ActionKind.StoreName, payload: args.value });
    };

    const handleDateRangeChange = (args: { dateFrom: string; dateTo: string }) => {
        dispatch({ type: ActionKind.StorePeriod, payload: args });
        closePeriodModal();
    };

    const onCommentChange = (args: { value: string; name: undefined; id: undefined }) => {
        dispatch({ type: ActionKind.StoreComment, payload: args.value });
    };

    const timeFromHandler = (args: { value: string }) => {
        args.value && dispatch({ type: ActionKind.StoreTimeFrom, payload: args.value });
    };

    const timeToHandler = (args: { value: string }) => {
        args.value && dispatch({ type: ActionKind.StoreTimeTo, payload: args.value });
    };

    const handleReportingObjectsChange = (args: IChangeOptionsArgs) => {
        dispatch({
            type: ActionKind.StoreSelectedReportingObjectsIds,
            payload: args.newOptions.selectedReportingObjectsIds || [],
        });
    };

    const onUpdate = () => {
        if (event) {
            dispatch({
                type: ActionKind.StoreEventToUpdate,
                payload: { event },
            });
        }
    };

    const onSubmit = useCallback(() => {
        if (selectedLocationId && timeZone) {
            dispatch({
                type: ActionKind.StoreEventsToCreate,
                payload: {
                    projectLocationId: selectedLocationId,
                    timeZone,
                },
            });
        }
    }, [selectedLocationId, timeZone]);

    const onNavigateConfig = () => {
        const path = modulesConfig.find((item: IModuleConfig) => {
            return item.module_id === 'Configuration:Events';
        })?.service.path;

        path && navigate(path);
    };

    const eventTypeOptions = eventTypes.map((item) => ({ ...item, text: item.name }));

    const selectedEventType = useMemo(() => {
        return state.eventType ? [{ ...state.eventType, text: state.eventType?.name }] : null;
    }, [state.eventType]);

    const handleEventTypeSelect = (args: TSelectedOptions) => {
        dispatch({ type: ActionKind.StoreEventType, payload: args[0] });
    };

    return (
        <Wrapper>
            <Modals title="" topColor="fff" modalStatus={periodModalStatus} closeModal={closePeriodModal}>
                <DateRangePicker showFutureDates={true} handleDateRangeChange={handleDateRangeChange} />
            </Modals>
            <Modals
                modalStatus={deleteConfirmModalStatus}
                closeModal={closeDeleteConfirmModal}
                title={t('Delete Event')}
                topColor="fff"
            >
                <DeleteConfirmModalBody
                    closeModal={closeDeleteConfirmModal}
                    closeEventModalBody={closeModal}
                    eventId={event?.id}
                />
            </Modals>
            <InputWrapper>
                <Label isRequired={true}>{t('Event name')}</Label>
                <TextInput
                    handleChange={onNameChange}
                    hasError={state.nameError}
                    outsideValue={state.name}
                    fullWidth={true}
                />
            </InputWrapper>
            <InputWrapper>
                <EventTypeAndPeriodWrapper>
                    <HalfWidthWrapper>
                        <Label isRequired={true}>{t('Event type')}</Label>
                        <div key={String(selectedEventType)}>
                            <Select
                                testId={`${currentModuleID}_Select event type`}
                                fullWidth={true}
                                showFilter={false}
                                options={eventTypeOptions}
                                mode="single"
                                handleSelect={handleEventTypeSelect}
                                outsideSelected={selectedEventType}
                                text={t('Select event type')}
                                labelText=""
                                iconType="chevron-down"
                            />
                        </div>
                    </HalfWidthWrapper>
                    <HalfWidthWrapper>
                        <Label isRequired={false}>{t('Add type')}</Label>
                        <div>
                            <Button
                                style={{ width: '100%' }}
                                disabled={isDisabledButtonAdd}
                                onClick={onNavigateConfig}
                                text={t('Add new event type')}
                            />
                        </div>
                    </HalfWidthWrapper>
                    <HalfWidthWrapper>
                        <Label isRequired={true}>{t('Event period')}</Label>
                        <div>
                            <Button
                                style={{ width: 'auto' }}
                                onClick={openPeriodModal}
                                text={
                                    state?.period.dateFrom
                                        ? `${
                                              state?.period.dateFrom === state?.period.dateTo
                                                  ? stringDate(state?.period.dateFrom, lang, '', 'd MMMM yyyy')
                                                  : stringDate(
                                                        {
                                                            dateFrom: state?.period.dateFrom,
                                                            dateTo: state?.period.dateTo,
                                                        },
                                                        lang,
                                                        '',
                                                        'd MMMM yyyy',
                                                    )
                                          }`
                                        : t('Select event period')
                                }
                            />
                        </div>
                    </HalfWidthWrapper>
                </EventTypeAndPeriodWrapper>
            </InputWrapper>
            {state?.period.dateFrom && (
                <InputWrapper>
                    <TimesWrapper>
                        <HalfWidthWrapper>
                            <Label isRequired={true}>{t('Time from')}</Label>
                            <TextInput
                                handleChange={timeFromHandler}
                                outsideValue={state.timeFrom}
                                hasError={state.timeFromError}
                                placeholder="00:00"
                                fullWidth={true}
                            />
                        </HalfWidthWrapper>
                        <HalfWidthWrapper>
                            <Label isRequired={true}>{t('Time to')}</Label>
                            <TextInput
                                handleChange={timeToHandler}
                                outsideValue={state.timeTo}
                                hasError={state.timeToError}
                                placeholder="00:00"
                                fullWidth={true}
                            />
                        </HalfWidthWrapper>
                    </TimesWrapper>
                </InputWrapper>
            )}
            <InputWrapper>
                <ObjectsSelect
                    localCurrentOptions={{ selectedReportingObjectsIds: state.selectedReportingObjectsIds }}
                    changeOptions={handleReportingObjectsChange}
                />
                <ReportingObjectsSelect selectedReportingObjectsIds={state.selectedReportingObjectsIds} />
            </InputWrapper>
            <InputWrapper>
                <Label isRequired={false}>{t('Comment')}</Label>
                <TextInput
                    handleChange={onCommentChange}
                    outsideValue={state.comment}
                    isMultiLine={true}
                    fullWidth={true}
                />
            </InputWrapper>
            <ButtonsWrapper>
                {event ? (
                    <>
                        <Button
                            disabled={!!state.eventsToCreate.length || !!state.eventToUpdate}
                            onClick={onDelete}
                            baseColor="accent"
                        >
                            {t('Delete')}
                        </Button>
                        <Button
                            appearance="primary"
                            disabled={!!state.eventsToCreate.length || !!state.eventToUpdate}
                            onClick={onUpdate}
                        >
                            {t('Update')}
                        </Button>
                    </>
                ) : (
                    <Button
                        appearance="primary"
                        disabled={!!state.eventsToCreate.length || !!state.eventToUpdate}
                        onClick={onSubmit}
                    >
                        {t('Add new event')}
                    </Button>
                )}
            </ButtonsWrapper>
        </Wrapper>
    );
};

export default EventModalBody;

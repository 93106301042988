import styled from 'styled-components';

export const ListContainer = styled.ul`
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: sans-serif;
    max-height: 40vh;
    overflow-y: auto;

    margin-bottom: 10px;
    border-bottom: 1px solid var(--color-border-gray);
`;

export const ListItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px; /* Fixed height for consistent row sizing */
    padding: 5px 10px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: ${(p) => p.theme.colors.secondaryLight};
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

    &:last-child {
        margin-bottom: 0;
    }
`;

export const TextBox = styled.div`
    flex: 3; /* Adjusts width proportion for the text */
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--color-text-primary);
`;

export const ValueBox = styled.div<{ bgColor: string }>`
    flex: 1; /* Adjusts width proportion for the value */
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 700;
    padding: 5px;
    color: ${(p) => p.color};
    background-color: ${(p) => p.bgColor};
    border-radius: 4px;
`;

export const ButtonContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
    gap: 10px;
`;

export const StyledBodyWrapper = styled.div`
    height: 80vh;
    width: 90vw;
`;

export const ModalWrapper = styled.div`
    overflow: auto;
`;

export const ChartWrapper = styled.div<{ windowSize: { width: number; height: number } }>`
    width: ${(p) => p.windowSize.width - 80 + 'px'};
    /* height: ${(p) => p.windowSize.height / 2 - 120 + 'px'}; */
    /* overflow: auto; */
`;

export const CenteredCell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

import { FC, memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import WidgetTitle from '../../../../../components/Wrappers/WidgetTitle/WidgetTitle';
import WidgetTitleWrapper from '../../../../../components/Wrappers/WidgetTitleWrapper/WidgetTitleWrapper';
import EventCard from '../../../../../components/EventCard/EventCard';
import usePrepareData from './hooks/usePrepareData';
import { CarouselWrapper, NoEventsText, Period, SlideArrowWrapper } from './styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { storeSelectedEventId } from '../../reducer';
import WidgetAdditionalControls from '../../../../../components/WidgetAdditionalControls/WidgetAdditionalControls';
import { reloadWidgets } from '../../../EventsMap/widgets/EventsMapWidget/reducer';
import Icon from '../../../../../components/Icon/Icon';
import { useSendSimpleAnalytics } from '../../../../../hooks/useAnalytics';
import { generalReducerValues } from '../../../../../General.reducer';
import WidgetWrapper from '../../../../../components/Wrappers/WidgetWrapper/WidgetWrapper';
import { shortStringDate } from '../../../../../tools/Strings/shortStringDate';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';

interface IProps {
    /** Название модуля, в котором находится данныый виджет */
    moduleName: string;
}

/**
 * Компонент для отображения слайдера с событиями за выбранный основной период
 */
const EventsCarousel: FC<IProps> = memo(({ moduleName }) => {
    const { lang } = useSelector(generalReducerValues);
    const [swiperInstance, setSwiperInstance] = useState<SwiperCore>();
    const { period, filteredEvents } = usePrepareData({ moduleName });
    const [isSwiperBeginning, setSwiperBeginning] = useState(false);
    const [isSwiperEnd, setSwiperEnd] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { navigate } = useCustomNavigate();
    const ref = useRef(null);

    const sendAnalytics = useSendSimpleAnalytics();

    useEffect(() => {
        if (swiperInstance) {
            setSwiperBeginning(swiperInstance.isBeginning);
            setSwiperEnd(swiperInstance.isEnd);
        }
    }, [swiperInstance, period]);

    const onSlideChange = (swiper: SwiperCore) => {
        setSwiperBeginning(swiper.isBeginning);
        setSwiperEnd(swiper.isEnd);
    };

    const storeSelectedEventIdTrans = (id: number) => {
        sendAnalytics('events_carousel_event_selection');
        dispatch(storeSelectedEventId(id));
        navigate('/events/events');
    };

    const changeSlide = (mode: 'next' | 'prev') => () => {
        if (mode === 'next') {
            swiperInstance?.slideNext();
        }

        if (mode === 'prev') {
            swiperInstance?.slidePrev();
        }
    };

    return (
        <WidgetWrapper ref={ref}>
            <WidgetTitleWrapper>
                <WidgetTitle>
                    {`${t('Events')}: ${shortStringDate(period.value, lang)}`}
                    <WidgetAdditionalControls
                        pdfDownloadData={{ targetRef: ref }}
                        reloadHandler={reloadWidgets}
                        widgetName="Events"
                    />
                </WidgetTitle>
            </WidgetTitleWrapper>
            {/* <Period>{period.stringValue}</Period> */}
            <CarouselWrapper>
                {filteredEvents.length ? (
                    <>
                        <Swiper
                            key={JSON.stringify([period, filteredEvents])}
                            onSlideChange={onSlideChange}
                            onSwiper={setSwiperInstance}
                            spaceBetween={15}
                            slidesPerView={4}
                        >
                            {filteredEvents.map((event) => {
                                return (
                                    <SwiperSlide key={event.id}>
                                        <EventCard
                                            storeSelectedEventIdTrans={storeSelectedEventIdTrans}
                                            event={event}
                                        />
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                        <SlideArrowWrapper hide={isSwiperBeginning} onClick={changeSlide('prev')} side="left">
                            <Icon type="chevron-left" />
                        </SlideArrowWrapper>
                        <SlideArrowWrapper hide={isSwiperEnd} onClick={changeSlide('next')} side="right">
                            <Icon type="chevron-right" />
                        </SlideArrowWrapper>
                    </>
                ) : (
                    <NoEventsText>{t('No events during the period')}</NoEventsText>
                )}
            </CarouselWrapper>
        </WidgetWrapper>
    );
});

export default EventsCarousel;

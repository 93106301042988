// import { KonvaEventObject } from 'konva/lib/Node';
import React from 'react';
import { Shape } from 'react-konva';

import { PERIMETER_OPACITY, PERIMETER_COLOR, UNIDENTIFIED_LAYER_COLOR } from 'src/constants';

import { Props } from './interfaces';

const Perimeter: React.FC<Props> = ({
    object,
    opacity = PERIMETER_OPACITY,
    selectObject,
    onMouseOverLeave,
    selectedMarker,
    heatColor,
}) => {
    // const onMouseOver = (e: KonvaEventObject<MouseEvent>) => {
    //     e.cancelBubble = true;
    //     onMouseOverLeave(object.front_id);
    // };

    // const onMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    //     e.cancelBubble = true;
    //     onMouseOverLeave(null);
    // };
    const fllColor = heatColor ?? PERIMETER_COLOR;
    const fill = selectedMarker !== object?.frontId ? fllColor : UNIDENTIFIED_LAYER_COLOR;

    const key = object?.frontId ?? 'ShapePerimeter!@##11';
    // console.log(key, object)
    return (
        <Shape
            id={key}
            key={key}
            // onMouseOver={onMouseOver}
            // onMouseLeave={onMouseLeave}
            // onClick={onObjectClick}
            sceneFunc={(context, shape) => {
                context.beginPath();
                context.moveTo(object.coords.coordinates[0][0], object.coords.coordinates[0][1]);
                object.coords.coordinates.forEach((coord: number[], i: number) => {
                    if (i > 0) {
                        context.lineTo(coord[0], coord[1]);
                    }
                });
                context.lineTo(object.coords.coordinates[0][0], object.coords.coordinates[0][1]);
                context.fillStrokeShape(shape);
            }}
            opacity={opacity}
            stroke={'rgb(155, 155, 155)'}
            fill={fill}
            strokeWidth={5}
        />
    );
};

export default Perimeter;

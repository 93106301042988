import { IModuleConfig } from '../General.interfaces';
import { DS } from './constants';
import i18 from 'i18next';

const t = i18.t('translationSidebar');

export const modulesConfig: IModuleConfig[] = [
    {
        tags: ['Dashboard designer', 'Object panels'],
        module_name: 'Dashboard designer:Object panels',
        module_id: 'Dashboard designer:Object panels',
        hasOptionsPanel: true,
        disabled: false,
        service: {
            path: '/dashboard-designer/object-panels',
        },
        icon: 'ObjectPanels',
        permissions: ['app/client | modules/dashboard-designer/object-panels | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['mainObject', 'comparisonObjects'],
            byL: [],
            byM: ['mainPeriod', 'comparePeriods'],
        },
    },
    {
        tags: ['Dashboard designer', 'Portfolio’s panels'],
        module_name: 'Dashboard designer:Portfolio’s panels',
        module_id: 'Dashboard designer:Portfolio’s panels',
        hasOptionsPanel: true,
        disabled: true,
        service: {
            path: '/dashboard-designer/portfolio-panels',
        },
        icon: 'PortfolioPanels',

        // version: '𝛃',
        permissions: ['app/client | modules/dashboard-designer/portfolio-panels | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['mainObject', 'comparisonObjects'],
            byL: [],
            byM: ['mainPeriod', 'comparePeriods'],
        },
    },
    {
        tags: ['Dashboard designer', 'Antifraud panels'],
        module_name: 'Dashboard designer:Antifraud panels',
        module_id: 'Dashboard designer:Antifraud panels',
        hasOptionsPanel: true,
        disabled: true,
        service: {
            path: '/dashboard-designer/antifraud-panels',
        },
        icon: 'AntifraudPanels',

        // version: '𝛃',
        permissions: ['app/client | modules/dashboard-designer/antifraud-panels | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['mainObject', 'comparisonObjects'],
            byL: [],
            byM: ['mainPeriod', 'comparePeriods'],
        },
    },
    {
        tags: ['Events', 'Events map'],
        module_name: 'Events:Events map',
        module_id: 'Events:Events map',
        hasOptionsPanel: false,

        service: {
            path: '/events/events-map',
        },
        icon: 'EventsMap',

        permissions: ['app/client | modules/events/events-map | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['selectedReportingObjectsIds'],
            byL: ['mainZoneGroup'],
            byM: [],
        },
    },
    {
        tags: ['Events', 'Events'],
        module_name: 'Events:Events',
        module_id: 'Events:Events',
        hasOptionsPanel: false,

        service: {
            path: '/events/events',
        },
        icon: 'Events',

        permissions: ['app/client | modules/events/events | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['selectedReportingObjectsIds', 'filters'],
            byL: ['mainZoneGroup'],
            byM: [
                'mainPeriod',
                'comparePeriods',
                'selectedMetrics',
                `objectSelect${DS}Events:Events${DS}DynamicsPMO`,
                `metricSelect${DS}Events:Events${DS}DynamicsPMO`,
                `detailSelect${DS}Events:Events${DS}DynamicsPMO`,
            ],
        },
    },

    // {
    // tags:[],
    // module_name: 'Events:Efficiency',
    //     module_id: 'events/events-efficiency',
    //     hasOptionsPanel: false,

    //     service: {
    //         path: '/events/events-efficiency',
    //     },

    //     icon: 'DecisionsConfiguration',
    //     version: '𝛃',
    //     permissions: ['app/client | modules/events-efficiency | feature/view'],
    //     store_method: {
    //         global: ['pl'],
    //         byML: [`events`],
    //         byL: [],
    //         byM: [`mainPeriod`, `additionalFields`],
    //     },
    // },

    // {
    // tags:[],//
    // module_name: 'Management decisions:Decisions map',
    //     module_id: 'management-decisions/decisions-map',
    //     hasOptionsPanel: false,

    //     service: {
    //         path: '/management-decisions/decisions-map',
    //     },
    //     icon: 'DecisionsMap',

    //     version: '𝛃',
    //     permissions: ['app/client | modules/decisions-map | feature/view'],
    //     store_method: {
    //         global: ['pl'],
    //         byML: ['selectedReportingObjectsIds'],
    //         byL: ['mainZoneGroup'],
    //         byM: [],
    //     },
    // },
    // {
    // tags:[],//
    // module_name: 'Management decisions:Decisions configuration',
    //     module_id: 'management-decisions/decisions-configuration',
    //     hasOptionsPanel: false,

    //     service: {
    //         path: '/management-decisions/decisions-configuration',
    //     },

    //     icon: 'DecisionsConfiguration',
    //     version: '𝛃',
    //     permissions: ['app/client | modules/decisions-configuration | feature/view'],
    //     store_method: {
    //         global: ['pl'],
    //         byML: ['selectedReportingObjectsIds'],
    //         byL: ['mainZoneGroup'],
    //         byM: [],
    //     },
    // },
    // {
    // tags:[],//
    // module_name: 'Management decisions:Efficiency',
    //     module_id: 'management-decisions/decisions-efficiency',
    //     hasOptionsPanel: false,

    //     service: {
    //         path: '/management-decisions/decisions-efficiency',
    //     },

    //     icon: 'DecisionsConfiguration',
    //     version: '𝛃',
    //     permissions: ['app/client | modules/decisions-efficiency | feature/view'],
    //     store_method: {
    //         global: ['pl'],
    //         byML: [`decisions`],
    //         byL: [],
    //         byM: [`mainPeriod`, `additionalFields`],
    //     },
    // },

    {
        tags: ['Performance', 'Overview'],
        module_name: 'Performance:Overview',
        module_id: 'Performance:Dashboard',
        hasOptionsPanel: true,

        service: {
            path: '/performance/overview',
        },

        icon: 'Overview',
        permissions: ['app/client | modules/performance/dashboard | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['selectedReportingObjectsIds', 'filters'],
            byL: ['mainZoneGroup'],
            byM: [
                'mainPeriod',
                'comparePeriods',
                'selectedMetrics',
                'detail',
                `metricSelect${DS}Performance:Dashboard${DS}DynamicsPMO`,
                `objectSelect${DS}Performance:Dashboard${DS}DynamicsPMO`,
                `detailSelect${DS}Performance:Dashboard${DS}DynamicsPMO`,
            ],
        },
    },
    {
        tags: ['Performance', 'Year over year'],
        module_name: 'Performance:Year over year',
        module_id: 'Performance:Year over year',
        hasOptionsPanel: true,

        service: {
            path: '/performance/year-over-year',
        },

        icon: 'YearOverYear',
        permissions: ['app/client | modules/performance/year-over-year | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['selectedReportingObjectsIds', 'yoyYearsSettings'],
            byL: ['mainZoneGroup'],
            byM: [
                'selectedMetrics',
                'yoyTableSettings',
                `detailSelect${DS}Performance:Year over year${DS}DynamicsByPeriods${DS}DetailedPeriodModal`,
                `objectSelect${DS}Performance:Year over year${DS}DynamicsByPeriods${DS}DetailedPeriodModal`,
                `metricSelect${DS}Performance:Year over year${DS}DynamicsByPeriods${DS}DetailedPeriodModal`,
            ],
        },
    },
    {
        tags: ['Performance', 'Visitors inside'],
        module_name: 'Performance:Visitors inside',
        module_id: 'Performance:Visitors inside',
        hasOptionsPanel: true,

        service: {
            path: '/performance/visitors-inside',
        },

        icon: 'VisitorsInside',
        permissions: ['app/client | modules/performance/visitors-inside | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['selectedReportingObjectsIds'],
            byL: ['mainZoneGroup'],
            byM: ['mainPeriod', 'comparePeriods', 'visitorsInsideChartDetailing'],
        },
    },
    {
        tags: ['Performance', 'Ratings'],
        module_name: 'Performance:Ratings',
        module_id: 'Performance:Ratings',
        hasOptionsPanel: true,

        service: {
            path: '/performance/ratings',
        },

        icon: 'Ratings',
        permissions: ['app/client | modules/performance/ratings | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [],
            byL: ['mainZoneGroup'],
            byM: ['mainPeriod', 'comparePeriods'],
        },
    },
    {
        tags: [],
        module_name: 'Performance:Path to purchase',
        module_id: 'Performance:Path to purchase',
        hasOptionsPanel: true,

        service: {
            path: '/performance/path-to-purchase',
        },

        icon: 'PathToPurchase',
        permissions: ['app/client | modules/performance/path-to-purchase | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['selectedReportingObjectsIds', 'pathToPurchaseGroupsOfZonesSettings'],
            byL: ['mainZoneGroup'],
            byM: [
                'mainPeriod',
                'comparePeriods',
                'selectedMetrics',
                'pathToPurchaseFunnelStepsSettings',
                'pathToPurchaseFunnelSettings',
            ],
        },
    },
    {
        tags: [],
        module_name: 'Network:Locations overview',
        module_id: 'Network:Locations overview',
        hasOptionsPanel: true,

        service: {
            path: '/network/locations-overview',
        },

        icon: 'PortfolioNetwork',
        permissions: ['app/client | modules/network/portfolio-overview | feature/view'],
        version: '𝛃',
        store_method: {
            global: ['pls'],
            byML: [],
            byL: ['mainZoneGroup'],
            byM: ['mainPeriod', 'comparePeriods', 'selectedMetrics', 'detail', 'selectedSingleMetric'],
        },
    },
    {
        tags: [],
        module_name: 'Network:Tenants Overview',
        module_id: 'network_tenants_overview',
        hasOptionsPanel: true,

        service: {
            path: '/network/tenants-overview',
        },

        icon: 'TenantsNetwork',
        permissions: ['app/client | modules/network/tenants-overview | feature/view'],
        version: '𝛃',
        store_method: {
            global: ['pls'],
            byML: [],
            byL: ['mainZoneGroup'],
            byM: [
                'mainPeriod',
                'comparePeriods',
                'selectedMetrics',
                'detail',
                'selectedSingleMetric',
                'groupObjectsOptions',
            ],
        },
    },
    {
        tags: [],
        module_name: 'Maps:Maps overview',
        module_id: 'Maps:Maps overview',
        hasOptionsPanel: true,

        service: {
            path: '/maps/maps-overview',
        },

        icon: 'MapsOverview',
        permissions: ['app/client | modules/maps/maps-overview | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [],
            byL: ['mainZoneGroup'],
            byM: ['mainPeriod', 'comparePeriods', 'selectedMetrics', 'mapsSettings'],
        },
    },
    {
        tags: [],
        module_name: 'Maps:Cross-visits',
        module_id: 'Maps:Cross-visits',
        hasOptionsPanel: true,

        service: {
            path: '/maps/cross-visits',
        },

        icon: 'MapsCrossVisits',
        permissions: ['app/client | modules/maps/cross-visits | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [],
            byL: ['mainZoneGroup'],
            byM: ['mainPeriod', 'comparePeriods', 'crossVisitsMapsSettings'],
        },
    },
    {
        tags: [],
        module_name: 'Sales:Sales analysis',
        module_id: 'Sales:Sales analysis',
        hasOptionsPanel: true,
        version: '𝛃',
        service: {
            path: '/sales/sales-analysis',
        },
        icon: 'SalesAnalysis',
        permissions: ['app/client | modules/sales/payment-analysis | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['filters', 'paymentAnalysisTenantSelect'],
            byL: ['mainZoneGroup'],
            byM: ['detail', 'mainPeriod', 'useVAT', 'comparePeriods', 'correctionReceipts'],
        },
    },
    {
        tags: [],
        module_name: 'Sales:POS monitoring',
        module_id: 'Sales:POS monitoring',
        hasOptionsPanel: true,
        version: '𝛃',
        service: {
            path: '/sales/pos-monitoring',
        },
        icon: 'SalesAnalysis',
        permissions: ['app/client | modules/sales/pos-analysis | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['filters', 'selectedReportingObjectsIds'],
            byL: [],
            byM: [
                'mainPeriod',

                `metrics${DS}Sales:POS monitoring${DS}PosAnalytics`,

                `metricSelect${DS}Sales:POS monitoring${DS}POS-Dynamics`,
                `detailSelect${DS}Sales:POS monitoring${DS}POS-Dynamics`,

                `detailSelect${DS}Sales:POS monitoring${DS}Structure-Dynamics`,
                `useVAT${DS}Sales:POS monitoring${DS}Structure-Dynamics`,
                `correctionReceipts${DS}Sales:POS monitoring${DS}Structure-Dynamics`,
            ],
        },
    },
    {
        tags: [],
        module_name: 'Work cases:Benchmarks',
        module_id: 'Work cases:Benchmarks',
        hasOptionsPanel: true,

        service: {
            path: '/cases/benchmarks',
        },
        icon: 'Benchmarks',
        permissions: ['app/client | modules/cases/benchmarks | feature/view'],
        version: '𝛃',
        store_method: {
            global: ['pl'],
            byML: ['selectedReportingObjectsIds'],
            byL: ['mainZoneGroup'],
            byM: [
                'mainPeriod',
                'comparePeriods',
                'selectedMetrics',
                'detail',

                'geoOptions',
                'mallTypeOptions',
                'myDetail',
                'category',
                'tenant',
            ],
        },
    },
    {
        tags: [],
        module_name: 'Work cases:Tenant discount calculator',
        module_id: 'Work cases:Tenant discount calculator',
        hasOptionsPanel: true,

        service: {
            path: '/cases/calculator',
        },

        icon: 'TenantDiscountCalculator',
        version: '𝛃',
        permissions: ['app/client | modules/cases/calculator | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [],
            byL: ['mainZoneGroup'],
            byM: [
                'mainPeriod',
                'comparePeriods',
                'indexingDate',
                'rentInMonth',
                'indexPercent',
                'receivables',
                'myMetrics',
                'myDetail',
                'discount',
                'discountPeriod',
                'tenant',
                'discountUnits',
                'referenceGroup:Henderson',
                'referenceGroup:Burger King',
            ],
        },
    },
    {
        tags: [],
        module_name: 'Leasing:Tenant overview',
        module_id: 'Leasing:Tenant overview',
        hasOptionsPanel: true,

        service: {
            path: '/leasing/tenant-overview',
        },
        icon: 'TenantOverview',
        permissions: ['app/client | modules/leasing/tenant-overview | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['filters', 'otherTenants', 'selectedReportingObjectsIds'],
            byL: ['mainZoneGroup'],
            byM: [
                'mainPeriod',
                'comparePeriods',
                'selectedMetrics',
                'selectedComparePeriod',
                `objectSelect${DS}Leasing:Tenant overview${DS}DynamicsPMO`,
                `metricSelect${DS}Leasing:Tenant overview${DS}DynamicsPMO`,
                `detailSelect${DS}Leasing:Tenant overview${DS}DynamicsPMO`,
            ],
        },
    },
    {
        tags: [],
        module_name: 'Leasing:Tenants',
        module_id: 'Leasing:Tenants',
        hasOptionsPanel: true,

        service: {
            path: '/leasing/tenants',
        },
        icon: 'Tenants',
        permissions: ['app/client | modules/leasing/tenants | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['filters', 'selectedReportingObjectsIds'],
            byL: ['mainZoneGroup'],
            byM: ['mainPeriod', 'comparePeriods', 'selectedMetrics'],
        },
    },
    {
        tags: [],
        module_name: 'Leasing:Cross-visits',
        module_id: 'Leasing:Cross-visits',
        hasOptionsPanel: true,

        service: {
            path: '/leasing/cross-visits',
        },

        icon: 'CrossVisits',
        permissions: ['app/client | modules/leasing/cross-visits | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['selectedReportingObjectsIds', 'filters', 'leasingCrossVisitsREportingObjectType'],
            byL: ['mainZoneGroup'],
            byM: ['mainPeriod'],
        },
    },
    {
        tags: [],
        module_name: 'Services:Map service',
        module_id: 'Services:Map service',
        hasOptionsPanel: true,

        service: {
            path: '/services/map-service',
        },
        icon: 'MapService',
        permissions: ['core/map-service | fpc-monitoring | feature/view'],
        store_method: {
            global: [],
            byML: [],
            byL: ['mainZoneGroup'],
            byM: [],
        },
    },

    {
        tags: [],
        module_name: 'Services:Project info',
        module_id: 'Services:Project info',
        hasOptionsPanel: false,

        service: {
            path: '/services/project-info',
        },
        version: '𝛃',
        icon: 'Metrics',
        permissions: ['app/client | modules/services/project-info | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [],
            byL: [],
            byM: ['o2o'],
        },
    },
    {
        tags: [],
        module_name: 'Services:Monitoring FPC',
        module_id: 'Services:FpcMonitoring',
        hasOptionsPanel: true,

        service: {
            path: '/services/fpc-monitoring',
        },
        version: '𝛃',
        icon: 'PresenceAwayRegular',
        permissions: ['app/client | modules/services/monitoring | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['selectedReportingObjectsIds'],
            byL: [],
            byM: ['period', 'mapsSettings'],
        },
    },

    {
        tags: [],
        module_name: 'Reports:Analytical reports',
        module_id: 'Reports:Analytical',
        hasOptionsPanel: true,

        service: {
            path: '/reports/analytical',
        },

        icon: 'AnalyticalReports',
        permissions: ['app/client | modules/reports/basic | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [
                `selectedReportingObjectsIds${DS}Basic`,
                `selectedReportingObjectsIds${DS}Comparison`,
                `mainObjectsIds${DS}Intersections`,
                `compareObjectsIds${DS}Intersections`,
                `selectedReportingObjectsIds${DS}ByTenants`,
                `selectedReportingObjectsIds${DS}ByTenantsComparison`,
            ],
            byL: [`mainZoneGroup`],
            byM: [
                `mainPeriod${DS}Basic`,
                `selectedMetrics${DS}Basic`,
                `detail${DS}Basic`,
                `additionalFields${DS}Basic`,
                `reportFormat${DS}Basic`,

                `mainPeriod${DS}Comparison`,
                `comparePeriods${DS}Comparison`,
                `selectedMetrics${DS}Comparison`,
                `detail${DS}Comparison`,
                `additionalFields${DS}Comparison`,
                `reportFormat${DS}Comparison`,

                `mainPeriod${DS}ByTenants`,
                `selectedMetrics${DS}ByTenants`,
                `detail${DS}ByTenants`,
                `additionalFields${DS}ByTenants`,
                `reportFormat${DS}ByTenants`,

                `mainPeriod${DS}ByTenantsComparison`,
                `comparePeriods${DS}ByTenantsComparison`,
                `selectedMetrics${DS}ByTenantsComparison`,
                `detail${DS}ByTenantsComparison`,
                `additionalFields${DS}ByTenantsComparison`,
                `reportFormat${DS}ByTenantsComparison`,

                `mainPeriod${DS}Intersections`,
                `additionalFields${DS}Intersections`,
                `reportFormat${DS}Intersections`,
            ],
        },
    },
    {
        tags: [],
        module_name: 'Reports:Detailed reports',
        module_id: 'Reports:Advanced reports',
        hasOptionsPanel: true,
        version: '𝛃',
        service: {
            path: '/reports/advanced',
        },
        info: 'Reports:Advanced reports',
        icon: 'AdvancedReport',
        permissions: ['app/client | modules/reports/reports-advanced | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [
                `selectedReportingObjectsIds${DS}SimplePaymentType`,
                `objectsToShow${DS}DetailedBills`,
                `reportObject${DS}DetailedBills`,
            ],
            byL: ['mainZoneGroup'],
            byM: [
                `mainPeriod${DS}SimplePaymentType`,
                `detail${DS}SimplePaymentType`,
                `additionalFields${DS}SimplePaymentType`,
                `reportFormat${DS}SimplePaymentType`,

                `mainPeriod${DS}DetailedBills`,
                `mainPeriod${DS}DetailedBillsArchive`,
                `additionalFields${DS}DetailedBills`,
            ],
        },
    },
    {
        tags: [],
        module_name: 'Reports:Antifraud reports',
        module_id: 'Reports:Antifraud reports',
        hasOptionsPanel: false,
        version: '𝛃',
        service: {
            path: '/reports/antifraud',
        },
        info: 'Reports:Antifraud reports',
        icon: 'AccessibilityErrorRegular',
        permissions: ['app/client | modules/reports/antifraud | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [],
            byL: [],
            byM: [],
        },
    },
    {
        tags: [],
        module_name: 'Reports:Expert reports',
        module_id: 'Reports:Reports from partners',
        hasOptionsPanel: true,

        service: {
            path: '/reports/partners',
        },

        icon: 'ReportsFromPartners',
        info: 'Reports:Reports from partners',
        permissions: ['app/client | modules/reports/reports-from-partners | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [`years${DS}Barthel`, `tableSettings${DS}Barthel`],
            byL: ['mainZoneGroup'],
            byM: [
                `mainPeriod${DS}Barthel`,
                `comparePeriods${DS}Barthel`,
                `metrics${DS}Barthel`,
                `colorSettings${DS}Barthel`,
            ],
        },
    },
    {
        tags: [],
        module_name: 'Configuration:Categories',
        module_id: 'Configuration:Categories',
        hasOptionsPanel: false,

        service: {
            path: '/configuration/categories',
        },

        icon: 'Categories',
        permissions: ['app/client | modules/configuration/categories | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [],
            byL: ['mainZoneGroup'],
            byM: [],
        },
    },
    {
        tags: [],
        module_name: 'Configuration:Events',
        module_id: 'Configuration:Events',
        hasOptionsPanel: false,

        service: {
            path: '/configuration/events',
        },

        icon: 'ConfigurationEvents',
        permissions: ['app/client | modules/configuration/events | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [],
            byL: ['mainZoneGroup'],
            byM: [],
        },
    },
    // {
    // tags:[],//
    // module_name: 'Configuration:Metrics',
    //     module_id: 'Configuration:Metrics',
    //     hasOptionsPanel: false,

    //     service: {
    //         path: '/configuration/metrics',
    //     },

    //     icon: 'Metrics',
    //     permissions: ['app/client | modules/configuration/metrics | feature/view'],
    //     store_method: {
    //         global: ['pl'],
    //         byML: [],
    //         byL: ['mainZoneGroup'],
    //         byM: ['mainPeriod', 'detail', 'objectTypes'],
    //     },
    // },
    {
        tags: [],
        module_name: 'Configuration:Sales import',
        module_id: 'configuration_sales_metrics_upload',
        hasOptionsPanel: true,

        service: {
            path: '/configuration/sales-import',
        },
        version: '𝛃',
        icon: 'Metrics',
        permissions: ['app/client | modules/configuration/sales-import | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['selectedReportingObjectsIds'],
            byL: [],
            byM: ['period', 'detailing'],
        },
    },
    {
        tags: [],
        module_name: 'Configuration:Rent income import',
        module_id: 'Configuration:Rent income import',
        hasOptionsPanel: true,

        service: {
            path: '/configuration/rent-import',
        },
        version: '𝛃',
        icon: 'Metrics',
        permissions: ['app/client | modules/configuration/rent-import | feature/view'],
        store_method: {
            global: ['pl'],
            byML: ['selectedReportingObjectsIds'],
            byL: [],
            byM: ['period'],
        },
    },
    {
        tags: [],
        module_name: 'Help:FAQ',
        module_id: 'Help:FAQ',
        hasOptionsPanel: false,

        service: {
            path: '/helps/faq',
        },

        icon: 'FAQ',
        permissions: ['app/client | modules/helps/faq | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [],
            byL: ['mainZoneGroup'],
            byM: [],
        },
    },
    {
        tags: [],
        module_name: 'Help:Metrics',
        module_id: 'Help:Metrics',
        hasOptionsPanel: false,

        service: {
            path: '/helps/metrics',
        },

        icon: 'HelpMetrics',
        permissions: ['app/client | modules/helps/metrics | feature/view'],
        store_method: {
            global: ['pl'],
            byML: [],
            byL: ['mainZoneGroup'],
            byM: [],
        },
    },
    // {
    // tags:[],//
    // module_name: 'none:Import metrics',
    //     module_id: 'none:Import metrics',
    // hasOptionsPanel:true,
    //  service: {
    //
    //
    //         path: '/import-metrics',
    //     },
    //     icons: {
    //         icon: 'import-metrics',
    //     },
    //     permissions: ['app/client | modules/import-metric | feature/view'],
    //     store_method: {
    //         global: ['pl', ],
    //         byML: [],
    //         byL: ['mainZoneGroup',],
    //         byM: [],
    //     },
    // },
];

import { isNull } from 'lodash';
import {
    IBgReport,
    IBgReportType,
    IReportingObject,
    TBgReportStatus,
    TLang,
} from '../../../../../../../../General.interfaces';

export interface ITableItem {
    id: number;
    reportType: string;
    comment: string;
    period: { dateFrom: string; dateTo: string };
    status: TBgReportStatus;
    jsonOutput: unknown;
    filesOutput: string[];
    createdAt: string;
}

interface IArgs {
    reports: IBgReport[];
    lang: TLang;
    backgroundReportTypesById: {
        [reportTypeId: string]: IBgReportType;
    };
    reportingObjectsById: {
        [x: string]: IReportingObject;
    };
}

const bgReportsTableDataAdapter = (args: IArgs): ITableItem[] => {
    const { backgroundReportTypesById, reports } = args;
    return reports.map((report) => {
        const { files, output, created_at, status, comment } = report;

        return {
            id: report.id,
            reportType: backgroundReportTypesById[report.report_type]?.name || '',
            period: { dateFrom: report.date_from, dateTo: report.date_to },
            status: status,
            comment: !comment ? '' : comment,
            jsonOutput: output,
            filesOutput: files,
            createdAt: created_at,
        };
    });
};

export default bgReportsTableDataAdapter;

import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { DateTime, Interval } from 'luxon';

import { snakeToCamelCase, transformFields, emptyArray, camelToSnakeCase } from 'src/tools';
import { useGeneralSelector } from 'src/hooks';

import { getAny } from '../../getAny';
import { generateUrl } from '../../helpers/generateUrl';

import { MapSensorType } from '../MapService/interfaces';

const DEFAULT_SENSORS_FREQUENCY_IN_MINUTES = 15;

export type FpcSensorStatus = {
    ipointMarker: string;
    sensorId: number;
    sensorSerial: string;
    sensorType: MapSensorType;
    values: Array<{ t: string; v: number }>;
};

const transformResponse = (response: Array<object>): Array<FpcSensorStatus> => {
    if (!response.length) {
        return emptyArray;
    }

    return response.map((versionInfo) => transformFields<FpcSensorStatus>(versionInfo, snakeToCamelCase));
};

export const useFetchSensorsStatuses = ({
    locationId,
    dateFrom,
    dateTo,
    timeFrequencyInMinutes = DEFAULT_SENSORS_FREQUENCY_IN_MINUTES,
}: {
    locationId?: number;
    dateFrom: string;
    dateTo: string;
    timeFrequencyInMinutes?: number;
}) => {
    const timeFreq = `${timeFrequencyInMinutes}min`;
    const { token, urlsByServices } = useGeneralSelector();

    const sensorsStatusesUrl = urlsByServices?.['fpc/metric-storage'].SENSORS_STATUSES_URL;
    const requestUrl = useMemo(() => {
        if (!locationId || !token || !dateFrom || !dateTo || !sensorsStatusesUrl) {
            return null;
        }

        const params = { locationId };

        const query = transformFields(
            {
                dateFrom: DateTime.fromISO(dateFrom)
                    .startOf('day')
                    .setZone('UTC')
                    .toISO({ includeOffset: true }),
                dateTo: DateTime.fromISO(dateTo)
                    .endOf('day')
                    .minus({ milliseconds: 999 })
                    .setZone('UTC')
                    .toISO({ includeOffset: true }),
                timeFreq,
            },
            camelToSnakeCase,
        );

        return generateUrl(`${sensorsStatusesUrl}:locationId`, { params, query });
    }, [locationId, dateFrom, dateTo, timeFreq, token, sensorsStatusesUrl]);

    // console.log(requestUrl, sensorsStatusesUrl)
    const isQueryEnabled = !!requestUrl && !!locationId && !!token;

    const result = useQuery({
        queryKey: [requestUrl, locationId],
        queryFn: async () => {
            const response = await getAny(requestUrl!, token);
            if (!response?.data) {
                return emptyArray;
            }

            const camelizedResult = transformResponse(response.data);

            return camelizedResult.map((status) => {
                const sortedVals = status.values.sort((a, b) => new Date(a.t).getTime() - new Date(b.t).getTime());
                return { ...status, values: sortedVals } as FpcSensorStatus;
            });
        },
        staleTime: timeFrequencyInMinutes * 60 * 1000,
        enabled: isQueryEnabled,
    });

    return result;
};

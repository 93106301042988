import { Wrapper } from './styles';
import { useTranslation } from 'react-i18next';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';

/**
 * Компонент для отображения ошибки
 */
const ErrorMessage = () => {
    const { navigate } = useCustomNavigate();
    const { t } = useTranslation();

    const onLinkClick = () => {
        navigate('/events/events-map');
    };

    return (
        <Wrapper>
            {t(`Please, select an event in the`)} <span onClick={onLinkClick}>{t('Events Map')}</span>
        </Wrapper>
    );
};

export default ErrorMessage;

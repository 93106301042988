import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { toggleArray } from 'src/tools';

import * as constants from '../../../../constants/constants';
import CollapseHeader from '../CollapseHeader/CollapseHeader';
import ModuleName from '../ModuleName/ModuleName';
import { IMenuProps, ISection, ISelectModuleArgs, ISubsection } from '../../../../configurations';
import Collapse from '../../../../../Collapse/Collapse';
import { cabinetPreferencesValues, patchSidebar } from '../../../../../CabinetPreferences/reducer';
import { useAppDispatch } from '../../../../../../hooks/useSettings';
import { generalReducerValues } from '../../../../../../General.reducer';

import '../../../../styles.scss';

import { SidebarList } from './styles';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';

const Menu: React.FC<IMenuProps> = ({ menu, ...props }) => {
    const { t } = useTranslation('translationSidebar');
    const dispatch = useAppDispatch();
    const { navigate } = useCustomNavigate();
    const {
        sidebar: { openSections, activeSection },
    } = useSelector(cabinetPreferencesValues);
    const { currentModuleID, token, appCoreData } = useSelector(generalReducerValues);

    const onToggleCollapse = (sectionName: string) => (collapsed: boolean) => {
        dispatch(patchSidebar({ openSections: toggleArray(openSections, sectionName) }));
    };

    const moduleSelectHandler =
        ({ subsectionId, servicePath, selectedSection }: ISelectModuleArgs) =>
        () => {
            if (subsectionId.includes('Services:Map service') && token) {
                window.open(`https://${appCoreData?.services['core/map-service-front']}`, '_blank', 'noreferrer');
            } else if (selectedSection) {
                document.title = t(selectedSection);
                navigate(servicePath);
                activeSection !== selectedSection && dispatch(patchSidebar({ activeSection: selectedSection }));
            }
        };

    const sectionList = React.useCallback(
        (section: ISection) => {
            return section?.subsections.map((subsection: ISubsection, j: number) => {
                const active = currentModuleID === subsection.module_id && activeSection === subsection.chapterName;
                return (
                    <ModuleName
                        disabled={subsection.disabled}
                        active={active}
                        key={`${j}---${subsection.name}`}
                        subsection={subsection}
                        onClick={moduleSelectHandler({
                            subsectionId: subsection.module_id,
                            servicePath: subsection.servicePath,
                            selectedSection: subsection.chapterName,
                        })}
                    />
                );
            });
        },
        [currentModuleID, activeSection, t],
    );

    const result = menu.map((sec, i) => {
        if (sec.type === 'chapter') {
            const section = sec as ISection;
            const isCollapsed = !openSections?.includes(section.name);

            // Раскомментить если не нужен аккордион с лишь одной открытой секцией
            // const isCollapsed = collapsedSections.includes(section.name);

            const collapseHeader = (
                <CollapseHeader title={t(section.name)} icon={'caret-down'} isCollapsed={isCollapsed} />
            );
            return (
                <React.Fragment key={`${i}--${section.name}`}>
                    <Collapse
                        addClasses="HiddenOverflow"
                        hover="background-none"
                        collapseHeader={collapseHeader}
                        isCollapsed={isCollapsed}
                        toggleCollapse={onToggleCollapse(section.name)}
                    >
                        <SidebarList>{sectionList(section)}</SidebarList>
                    </Collapse>
                </React.Fragment>
            );
        } else if (sec.type === 'module') {
            const subsection = sec as ISubsection;
            const active = currentModuleID === subsection.module_id;
            return (
                <React.Fragment key={`${i}--${subsection.name}`}>
                    <SidebarList>
                        <ModuleName
                            active={active}
                            subsection={subsection as ISubsection}
                            onClick={moduleSelectHandler({
                                subsectionId: subsection.module_id,
                                servicePath: subsection?.servicePath,
                            })}
                        />
                    </SidebarList>
                </React.Fragment>
            );
        } else {
            return null;
        }
    });

    return result;
};

export default Menu;

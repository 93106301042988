import { useEffect } from 'react';
import { detectIncognito } from 'detectincognitojs';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';
import { storeNewAlert } from '../components/Alert/reducer';
import { IUrlParams } from '../General.interfaces';
import { changeServiceMode, generalReducerValues, storeToken } from '../General.reducer';
import { storeSharedPresetHash } from '../components/Preset/reducer';
import { useAppDispatch } from './useSettings';
import { storeSharedDashboardHash } from 'src/Dashboards.reducer';
import { current } from '@reduxjs/toolkit';
import { useCustomNavigate } from './useCustomNavigate';

const useUrl = () => {
    const currentUrl = window.location.origin + window.location.pathname;
    const { urlsByServices } = useSelector(generalReducerValues);
    const dispatch = useAppDispatch();
    const { navigate } = useCustomNavigate();
    const location = useLocation();

    const [searchParams] = useSearchParams();

    useEffect(() => {
        urlsByServices && findUrlParams();
    }, [urlsByServices]);

    useEffect(() => {
        const service_mode = localStorage.getItem('service_mode');
        service_mode && dispatch(changeServiceMode(true));
    }, []);

    /**
     * Ищем параметры в урле.
     */
    const findUrlParams = () => {
        const urlParams: IUrlParams = Object.fromEntries(searchParams);

        if (urlParams && urlParams['token'] && urlParams['service_mode'] === 'true') {
            detectIncognito().then((res) => {
                if (res.isPrivate) {
                    const localToken = localStorage.getItem('xtoken');
                    if (localToken) {
                        dispatch(
                            storeNewAlert({
                                type: 'error',
                                text: `Error with existing token!`,
                                duration: 30,
                            }),
                        );
                    } else {
                        localStorage.setItem('service_mode', urlParams['service_mode']);
                        dispatch(changeServiceMode(true));
                        localStorage.setItem('xtoken', urlParams['token']!);
                        dispatch(storeToken(urlParams.token!));
                    }
                } else {
                    dispatch(
                        storeNewAlert({
                            type: 'error',
                            text: `Please launch the browser in the incognito mode.`,
                            duration: 30,
                        }),
                    );
                }
            });
        } else if (urlParams?.['token']) {
            const token = urlParams?.['token'];
            token && localStorage.setItem('xtoken', token);
            token && dispatch(storeToken(token));
        } else if (urlParams?.['shared_preset']) {
            const hash = searchParams.get('shared_preset');
            hash && dispatch(storeSharedPresetHash(hash));
        } else if (urlParams?.['shared_dashboard']) {
            const hash = searchParams.get('shared_dashboard');
            hash && dispatch(storeSharedDashboardHash(hash));
        }

        const ssoUrl = urlsByServices?.['core/admin-service'].AUTH_URL;
        const samlUrl = urlsByServices?.['core/admin-service'].AUTH_SAML_URL;

        if (!urlParams.hasOwnProperty('token')) {
            if (urlParams.hasOwnProperty('saml_id')) {
                const samlId: string = urlParams['saml_id'];

                /**
                 * Если saml_id равен пустой строке то убираем
                 * соответствующее значение из localStorage и
                 * делаем редирект на дефолтную авторизацию
                 */
                if (samlId === '') {
                    localStorage.removeItem('saml_id');
                    ssoUrl && redirectToAuth(ssoUrl);
                } else {
                    localStorage.setItem('saml_id', samlId);
                    ssoUrl && samlUrl && redirectToSamlAuth(ssoUrl, samlUrl, samlId);
                }
            } else {
                const localToken = localStorage.getItem('xtoken');
                if (localToken) {
                    dispatch(storeToken(localToken));
                    navigate(location.pathname, { replace: false });
                } else {
                    const samlId = localStorage.getItem('saml_id');
                    if (samlId) {
                        ssoUrl && samlUrl && redirectToSamlAuth(ssoUrl, samlUrl, samlId);
                    } else {
                        ssoUrl && redirectToAuth(ssoUrl);
                    }
                }
            }
        }

        // Если параметры в урле были, убираем их кроме saml_id
        if (Object.keys(urlParams).length) {
            navigate(location.pathname);
        }
    };

    /**
     * Редирект на сервер аутентификации.
     */
    const redirectToAuth = (authUrl: string): void => window.location.replace(`${authUrl}?next=${currentUrl}`);

    const redirectToSamlAuth = (ssoUrl: string, samlUrl: string, samlId: string): void =>
        window.location.replace(`${samlUrl}${samlId}/login/?next=${ssoUrl}?next=${currentUrl}`);
};

export default useUrl;

export const CURRENCY = '₽';
export const DS = '::';
export const LATEST_DATE = '2035-01-01';

export const HANDSONTABLE_LICENSE_KEY = 'non-commercial-and-evaluation';
export const ZONES_MIN_CONTENT_PERCENTAGE = 99;
export const ZONES_WORD: string = 'Zones';
export const CATEGORY_WORD: string = 'Category';
export const DEFAULT_SEPARATOR_MODIFICATION_PREFIX: string = '--';
export const DEFAULT_SEPARATOR_NAME_SPACE_PREFIX: string = '-';
export const DEFAULT_SEPARATOR_ELEMENT_PREFIX: string = '__';

export const DEFAULT_CLASS_BTN_PREFIX: string = 'button';
export const DEFAULT_CLASS_BTN_GROUP_PREFIX: string = 'button-group';
export const DEFAULT_CLASS_BTN_GROUP_ITEM_PREFIX: string = 'button-group__item';
export const DEFAULT_CLASS_ICON_PREFIX: string = 'icon';
export const DEFAULT_CLASS_COLOR_PREFIX: string = 'color';
export const DEFAULT_CLASS_BACKGROUND_PREFIX: string = 'background';
export const DEFAULT_CLASS_SLIDER_PREFIX: string = 'atoms_slider';
export const DEFAULT_CLASS_HOVER_PREFIX: string = 'atoms-hover';
export const DEFAULT_CLASS_FOCUS_PREFIX: string = 'atoms-focus';
export const DEFAULT_CLASS_ACTIVE_PREFIX: string = 'atoms-active';
export const DEFAULT_CLASS_DIVIDER_PREFIX: string = 'atoms-divider';
export const DEFAULT_CLASS_TAG_PREFIX: string = 'molecules-tag';
export const DEFAULT_CLASS_SHADOW_PREFIX: string = 'shadow';
export const DEFAULT_CLASS_INPUT_PREFIX: string = 'form-control';
export const DEFAULT_CLASS_BORDER_RADIUS_PREFIX: string = 'border-radius';
export const DEFAULT_CLASS_SLIDER_ITEM_PREFIX: string = 'ruler-item';
export const DEFAULT_CLASS_MOLECULES_LIST_ITEM_PREFIX: string = 'molecules_list-item';
export const DEFAULT_CLASS_ORGANISMS_LIST_PREFIX: string = 'organisms_list';
export const DEFAULT_MOLECULES_LOADER_IS_ENABLED: boolean = true;
export const DEFAULT_MOLECULES_LOADER_ICON_TYPE: string = 'loader';

export const DEFAULT_INPUT_MAX_LENGTH: number = 999;
export const PATH_ICON_SPRITE: string = 'assets/crumbs_atomic-design-form/images/sprites/svg-sprite.svg';

export const DEFAULT_CLASS_PROGRESS_BAR_SIZE_PREFIX: string = 'atoms-progress--size';
export const DEFAULT_CLASS_PROGRESS_BAR_ALIGN_TEXT_PREFIX: string = 'text';
export const DEFAULT_CLASS_PROGRESS_BAR_MAX_VALUE: number = 100;

export const MINUTE_IN_MILLIS = 60000;

export const CHART_DESKTOP_HEIGHT = 500; // in pixels
export const CHART_MOBILE_HEIGHT = 400; // in pixels

// React-query keys TODO: move to reactQueryKeys file !!!
export const ANTIFRAUD_REPORTS_TYPE = 'mall-ai/antifraud-basic';
export const GET_ALL_ANTIFRAUD_REPORTS_RQ_KEY = 'GET_ALL_ANTIFRAUD_REPORTS_RQ_KEY';
export const GET_ALL_BG_REPORTS_RQ_KEY = 'GET_ALL_BG_REPORTS_RQ_KEY';
export const CREATE_BG_REPORT_RQ_KEY = 'CREATE_BG_REPORT_RQ_KEY';
export const GET_DETAILED_BILLS_PLAIN_RQ_KEY = 'GET_DETAILED_BILLS_PLAIN_RQ_KEY';

// Permissions
export const DASHBOARD_EDITOR_PERMISSION = 'app/client | dashboard-editor | feature/edit';

export const REACT_APP_REF = {
    current: document.getElementById('root')!,
};

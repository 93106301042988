import QS, { StringifyOptions } from 'query-string';
import template from 'lodash/template';

import { toString } from 'src/tools';

import { PathParams, QueryParams } from '../interfaces';

const stringifyOptions: StringifyOptions = {
    arrayFormat: 'comma',
    skipNull: true,
};
export const encodeObjectValues = (obj: PathParams = {}) =>
    Object.keys(obj).reduce(
        (r, k) => ({
            ...r,
            [k]: obj[k] && obj[k]!,
        }),
        {},
    );

const DEFAULT_OPTIONS = {
    params: {},
};

export type UrlTemplateOptions = {
    params?: PathParams;
    query?: QueryParams;
};

export function generateUrl(urlTemplate: string, { params = {}, query }: UrlTemplateOptions = DEFAULT_OPTIONS) {
    // need to support templaces like /adc/venues/search?businessname=*:searchQuery*
    // and to avoid url encode for queryparams like %A and other symbols sequencies
    const urlExecutor = template(urlTemplate, {
        interpolate: /:([\w_]+)/g,
    });

    const url = urlExecutor(encodeObjectValues(params));

    if (Boolean(query) === false) {
        return url;
    }

    const queriesString = decodeURIComponent(QS.stringify(query!, stringifyOptions).replace(/\+/g, ' '));
    if (url.includes('?')) {
        return `${url}&${queriesString}`;
    }
    return `${url}?${queriesString}`;
}